import React, { useState, useEffect, useRef } from 'react';
import { ExternalLink, Clock, Coins, FileCheck } from 'lucide-react';

// Existing helper functions remain the same
const formatTimeRemaining = (seconds) => {
  if (seconds < 60) return `${seconds} seconds`;
  
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
 
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}, ${minutes} minute${minutes > 1 ? 's' : ''}, ${remainingSeconds} seconds`;
  }
  
  return `${minutes} minute${minutes > 1 ? 's' : ''}, ${remainingSeconds} seconds`;
};

const formatUsdFee = (networkFee) => {
  const usdValue = networkFee * 0.000000000688354;
  return usdValue.toFixed(6);
};

const TruncatedAddress = ({ address, maxWidth = "300px" }) => {
  const truncate = (str) => {
    const start = str.slice(0, 7);
    const end = str.slice(-5);
    return `${start}...${end}`;
  };

  return (
    <div className={"truncate max-w-["+maxWidth+"]"} title={address}>
      <a 
        href={`https://polygonscan.com/address/${address}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-700 flex items-center"
      >
        {truncate(address)}
        <ExternalLink className="h-3 w-3 ml-1 shrink-0" />
      </a>
    </div>
  );
};

const TransactionDetails = ({ 
  title, 
  data, 
  timeRemaining, 
  status, 
  tokenSymbol, 
  transactionHash 
}) => (
  <div 
    className="p-2 md:p-4 bg-gray-50 rounded-md animate-[fadeIn_0.5s_ease-out]"
    style={{
      '@keyframes fadeIn': {
        '0%': { opacity: 0, transform: 'translateY(10px)' },
        '100%': { opacity: 1, transform: 'translateY(0)' }
      }
    }}
  >
    <h3 className="font-medium">{title}</h3>
    <div className="grid grid-cols-2 gap-x-4 gap-y-3 text-sm">
      <dt className="text-gray-600">Tokens:</dt>
      <dd className="font-bold text-green-600 text-lg">{data.tokensMinted} {tokenSymbol}</dd>

      <dt className="text-gray-600">To:</dt>
      <dd>
        <TruncatedAddress address={data.recipientAddress} />
      </dd>

      <dt className="text-gray-600">Network:</dt>
      <dd>Polygon</dd>

      <dt className="text-gray-600">Network fee:</dt>
      <dd>{data.networkFee} POL ({formatUsdFee(data.networkFee)} USD)</dd>

      <dt className="text-gray-600">Date:</dt>
      <dd>{data.transactionDate.toLocaleString()}</dd>

      <dt className="text-gray-600">Status:</dt>
      <dd className="flex items-center">
        {status === 'Complete' ? (
          <span className="flex h-2 w-2 rounded-full bg-green-500 mr-2"></span>
        ) : (
          <span className="flex h-2 w-2 rounded-full bg-yellow-400 mr-2"></span>
        )}
        {status}
      </dd>

      {timeRemaining > 0 ? (
        <>
          <dt className="text-gray-600">Time remaining:</dt>
          <dd className="flex items-center">
            <Clock className="h-4 w-4 text-gray-400 mr-1" />
            {formatTimeRemaining(timeRemaining)}
          </dd>
        </>
      ) : (
        <>
          <dt className="text-gray-600">Transaction:</dt>
          <dd>
            <a
              href={`https://polygonscan.com/tx/${transactionHash || ''}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700 flex items-center"
            >
              View on block explorer
              <ExternalLink className="h-4 w-4 ml-1" />
            </a>
          </dd>
        </>
      )}
    </div>
  </div>
);

const Results = ({ results }) => {
  const [timeRemaining, setTimeRemaining] = useState(parseInt(results.timeRemaining) || 60);
  const [status, setStatus] = useState(results.status || 'Pending');
  const [showTransaction, setShowTransaction] = useState(false);
  
  const [timeRemaining45Q, setTimeRemaining45Q] = useState(15);
  const [status45Q, setStatus45Q] = useState('Pending');
  const [show45QTransaction, setShow45QTransaction] = useState(false);
  
  const [timeRemainingVCM, setTimeRemainingVCM] = useState(20);
  const [statusVCM, setStatusVCM] = useState('Pending');
  const [showVCMTransaction, setShowVCMTransaction] = useState(false);
  
  // Refs for scrolling
  const carbonTokenRef = useRef(null);
  const q45TokenRef = useRef(null);
  const vcmTokenRef = useRef(null);
  
  // Original timer effect
  useEffect(() => {
    if (timeRemaining <= 0) {
      setStatus('Complete');
      return;
    }

    const timer = setInterval(() => {
      setTimeRemaining(prev => {
        const newTime = Math.max(0, prev - 1);
        if (newTime === 0) {
          setStatus('Complete');
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining]);

  // 45Q timer effect
  useEffect(() => {
    if (!show45QTransaction || timeRemaining45Q <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining45Q(prev => {
        const newTime = Math.max(0, prev - 1);
        if (newTime === 0) {
          setStatus45Q('Complete');
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining45Q, show45QTransaction]);

  // New VCM timer effect
  useEffect(() => {
    if (!showVCMTransaction || timeRemainingVCM <= 0) return;

    const timer = setInterval(() => {
      setTimeRemainingVCM(prev => {
        const newTime = Math.max(0, prev - 1);
        if (newTime === 0) {
          setStatusVCM('Complete');
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemainingVCM, showVCMTransaction]);

  // Click handlers
  const handleMintCarbonTokens = () => {
    setShowTransaction(true);
    setTimeout(() => {
      carbonTokenRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  };

  const handleMint45QTokens = () => {
    setShow45QTransaction(true);
    setTimeout(() => {
      q45TokenRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  };

  const handleMintVCMTokens = () => {
    setShowVCMTransaction(true);
    setTimeout(() => {
      vcmTokenRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  };

  return (
    <div className="space-y-4">
      {/* Reporting Data Section */}
      <div className="p-2 md:p-4 bg-gray-50 rounded-md">
        <h3 className="font-medium">Reporting Data</h3>
        <a 
          href={`https://ipfs.io/ipfs/${results.ipfsLink.replace('ipfs://', '')}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-blue-600 hover:text-blue-700 break-all flex items-center"
        >
          {results.ipfsLink}
          <ExternalLink className="h-4 w-4 ml-1" />
        </a>
      </div>

      {/* Two Column Layout */}
      <div className="grid grid-cols-12 gap-4 items-start">
        {/* Left Column - 2/3 width */}
        <div className="col-span-8 space-y-4">
          {/* MassCertificate Data Section */}
          <div className="p-2 md:p-4 bg-gray-50 rounded-md">
            <h3 className="font-medium h-8">MassCertificate Data</h3>
            <dl className="grid grid-cols-2 gap-2 text-sm">
              <dt className="text-gray-600">Total Mass:</dt>
              <dd>{results.massCertificateData.totalMass} tons</dd>
              <dt className="text-gray-600">Location:</dt>
              <dd>{results.massCertificateData.location}</dd>
              <dt className="text-gray-600">Timestamp:</dt>
              <dd>{results.massCertificateData.timestamp}</dd>
            </dl>
          </div>

          {/* Transaction Details Sections */}
          {showTransaction && (
            <div ref={carbonTokenRef}>
              <TransactionDetails
                title="CarbonToken Transaction Details"
                data={results}
                timeRemaining={timeRemaining}
                status={status}
                tokenSymbol="CT"
                transactionHash={results.transactionHash}
              />
            </div>
          )}

          {showVCMTransaction && (
            <div ref={vcmTokenRef}>
              <TransactionDetails
                title="VCMToken Transaction Details"
                data={results.vcmData}
                timeRemaining={timeRemainingVCM}
                status={statusVCM}
                tokenSymbol="VCM"
                transactionHash={results.vcmData.transactionHash}
              />
            </div>
          )}

          {show45QTransaction && (
            <div ref={q45TokenRef}>
              <TransactionDetails
                title="45QToken Transaction Details"
                data={results.q45Data}
                timeRemaining={timeRemaining45Q}
                status={status45Q}
                tokenSymbol="45Q"
                transactionHash={results.q45Data.transactionHash}
              />
            </div>
          )}
        </div>

        {/* Right Column - Token Actions */}
        <div className="col-span-4">
          {/* First button - Mint Carbon Tokens */}
          <div className="p-2 md:p-4 bg-gray-50 rounded-md">
            <h3 className="font-medium mb-4">Token Actions</h3>
            <button
              onClick={handleMintCarbonTokens}
              disabled={showTransaction}
              className={`w-full flex items-center justify-center px-4 py-3 rounded-lg transition-colors md:text-base text-xs
                ${showTransaction 
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
                }`}
            >
              <Coins className="w-5 h-5 mr-1 md:mr-2" />
              Mint Carbon Tokens
            </button>
          </div>

          {/* Second and Third buttons appear after first token minted */}
          {showTransaction && (
            <>
              <div className="p-2 md:p-4 bg-gray-50 rounded-md mt-4">
                <button
                  onClick={handleMintVCMTokens}
                  disabled={timeRemaining > 0 || showVCMTransaction}
                  className={`w-full flex items-center justify-center px-4 py-3 rounded-lg transition-colors md:text-base text-xs
                    ${timeRemaining > 0 || showVCMTransaction
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-blue-600 text-white hover:bg-blue-700'
                    }`}
                >
                  <Coins className="w-5 h-5 mr-1 md:mr-2" />
                  Mint VCM Tokens
                </button>
              </div>
              <div className="p-2 md:p-4 bg-gray-50 rounded-md">
                <button
                  onClick={handleMint45QTokens}
                  disabled={timeRemaining > 0 || show45QTransaction}
                  className={`w-full flex items-center justify-center px-4 py-3 rounded-lg transition-colors md:text-base text-xs
                    ${timeRemaining > 0 || show45QTransaction
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-blue-600 text-white hover:bg-blue-700'
                    }`}
                >
                  <Coins className="w-5 h-5 mr-1 md:mr-2" />
                  Mint 45Q Tokens
                </button>
              </div>
            </>
          )}

          {/* Third button appears after 45Q token minted */}
          {show45QTransaction && (
            <div className="p-2 md:p-4 bg-gray-50 rounded-md mt-4">
              <button
                onClick={() => window.open('https://www.irs.gov/pub/irs-pdf/f8933.pdf', '_blank')}
                disabled={timeRemaining45Q > 0}
                className={`w-full flex items-center justify-center px-4 py-3 rounded-lg transition-colors md:text-base text-xs
                  ${timeRemaining45Q > 0
                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    : 'bg-green-600 text-white hover:bg-green-700'
                  }`}
              >
                <FileCheck className="w-5 h-5 mr-1 md:mr-2" />
                Generate 8933 Form
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Results;