import React, { useState, useEffect } from 'react';
import { Check, X } from 'lucide-react';

const ProcessingStatus = ({ status, error, onRetry, onComplete }) => {  // Add onComplete prop
  const [progress, setProgress] = useState({
    ipfs: 0,
    blockchain: 0,
    tokens: 0
  });

  useEffect(() => {
    // Generate random completion times between 5-20 seconds
    const times = {
      ipfs: Math.random() * 15000 + 5000,
      blockchain: Math.random() * 15000 + 5000,
      tokens: Math.random() * 15000 + 5000
    };

    const interval = 50;
    const increments = {
      ipfs: (100 * interval) / times.ipfs,
      blockchain: (100 * interval) / times.blockchain,
      tokens: (100 * interval) / times.tokens
    };

    const timers = {};
    Object.keys(times).forEach(key => {
      timers[key] = setInterval(() => {
        setProgress(prev => {
          const newValue = Math.min(100, prev[key] + increments[key]);
          
          // Check if this update completes the tokens progress
          if (key === 'tokens' && prev[key] < 100 && newValue === 100) {
            // Small delay before triggering completion
            setTimeout(() => onComplete(), 500);
          }
          
          return { ...prev, [key]: newValue };
        });
      }, interval);
    });

    return () => {
      Object.values(timers).forEach(timer => clearInterval(timer));
    };
  }, [onComplete]); // Empty dependency array means this runs once on mount

  const StatusIndicator = ({ type, label }) => (
    <div className="flex items-center space-x-4">
      <div className="w-32 md:w-40">
        <span className="text-sm font-medium text-gray-700">{label}</span>
      </div>
      <div className="flex-1">
        {error ? (
          <div className="flex items-center">
            <X className="text-red-600 h-5 w-5" />
          </div>
        ) : progress[type] === 100 ? (
          <div className="flex items-center">
            <Check className="text-green-600 h-5 w-5" />
          </div>
        ) : (
          <div className="relative w-full">
            <div className="h-2 bg-gray-200 rounded-full w-full">
              <div 
                className="h-full bg-blue-600 rounded-full transition-all duration-100"
                style={{ width: `${progress[type]}%` }}
              />
            </div>
            <span className="absolute right-0 top-2 text-xs text-gray-500">
              {Math.round(progress[type])}%
            </span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <StatusIndicator 
          type="ipfs"
          label="Uploading to IPFS"
        />
        <StatusIndicator 
          type="blockchain"
          label="Storing in MassCertificate"
        />
        <StatusIndicator 
          type="tokens"
          label="Minting Tokens"
        />
      </div>
      
      {/* {!!error ? (
        <button 
        onClick={() => onRetry('retry')}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Retry Processing
        </button>
      ) : (
        <button 
          onClick={() => onRetry('error')}
          className="w-full px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700"
        >
          Simulate Error
        </button>
      )} */}
    </div>
  );
};

export default ProcessingStatus;